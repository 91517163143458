import React from 'react';
import { useFingerprint } from '../hooks/useFingerprint';

import 'normalize.css';
import '../scss/app.scss';
import { COLORS } from '../colors';

import Navbar from './navbar';
import Footer from './footer';

interface Props {
  children?: any;
}

export default ({ children }: Props) => {
  // generate and store fingerprint on initial page load
  useFingerprint();

  return (
    <div style={{ background: COLORS.white }}>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};
