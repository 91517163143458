import { useState, useEffect } from 'react';
import Fingerprint2 from 'fingerprintjs2';

export function useFingerprint(fallbackTimeout = 500) {
  const [fingerprint, setFingerprint] = useState<string | null>(null);

  const generateFingerprint = () =>
    Fingerprint2.get(components => {
      const values = components.map(c => c.value);
      const hash = Fingerprint2.x64hash128(values.join(''), 31);
      localStorage.setItem('fingerprint', hash);
      setFingerprint(hash);
    });

  useEffect(() => {
    const persisted = localStorage.getItem('fingerprint');

    if (persisted) {
      setFingerprint(persisted);
      return;
    }

    if ((window as any).requestIdleCallback) {
      (window as any).requestIdleCallback(generateFingerprint);
    } else {
      setTimeout(generateFingerprint, fallbackTimeout);
    }
  }, []);

  return fingerprint;
}
