import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import useConstant from 'use-constant';
import Logo from '../svgs/hamster.svg';
import './footer.scss';

import Instagram from '../svgs/social-instagram.svg';
import Facebook from '../svgs/social-facebook.svg';
import Twitter from '../svgs/social-twitter.svg';
import LinkedIn from '../svgs/social-linkedin.svg';

const copyrightYear = new Date().getFullYear();

const licenses = {
  joyPixels:
    'https://github.com/joypixels/emojione/blob/0aad7f9f7969f0187e4f50d12fdc113541a34ac3/LICENSE.md',
  fontAwesome: 'https://fontawesome.com/license',
};

function scrollToTop() {
  if (typeof window === 'undefined') return;
  window.scroll({ top: 0, behavior: 'smooth' });
}

interface Query {
  allAirtable: {
    edges: Array<{
      node: {
        data: {
          provider: string;
          href: string;
          enabled: boolean;
        };
      };
    }>;
  };
  articles: {
    edges: Array<{
      node: {
        data: {
          slug: string;
        };
      };
    }>;
  };
}

export default () => {
  const data: Query = useStaticQuery(graphql`
    query {
      allAirtable(filter: { table: { eq: "contact" } }) {
        edges {
          node {
            data {
              provider
              href
              enabled
            }
          }
        }
      }
      articles: allAirtable(
        filter: { table: { eq: "articles" }, data: { active: { eq: true } } }
      ) {
        edges {
          node {
            data {
              slug
            }
          }
        }
      }
    }
  `);
  const contact = useConstant(() => {
    const acc: {
      [provider: string]: { provider: string; href: string; enabled: boolean };
    } = {};
    const nodes = data.allAirtable.edges.map(e => e.node.data);

    for (const node of nodes) {
      acc[node.provider] = node;
    }

    return acc;
  });
  const showArticles = data.articles.edges.length > 0;

  return (
    <footer id="footer">
      <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
        <div className="footer-row">
          <button onClick={scrollToTop} className="footer-logo">
            Lease or Leave
            {/* <Logo /> */}
            <div className="footer-back-to-top">back to top</div>
          </button>
          <div className="footer-stretch">
            {/* <Link to="/about" className="footer-link">
              About
            </Link> */}
            {/* <Link to="/faq" className="footer-link">
              FAQs
            </Link> */}
            {showArticles && (
              <Link to="/articles" className="footer-link">
                Articles
              </Link>
            )}
          </div>
          {contact.instagram.enabled && (
            <div className="footer-icon hidden-mobile">
              <a href={contact.instagram.href}>
                <Instagram height={24} width={24} />
              </a>
            </div>
          )}
          {contact.facebook.enabled && (
            <div className="footer-icon hidden-mobile">
              <a href={contact.facebook.href}>
                <Facebook height={24} width={24} />
              </a>
            </div>
          )}
          {contact.twitter.enabled && (
            <div className="footer-icon hidden-mobile">
              <a href={contact.twitter.href}>
                <Twitter height={24} width={24} />
              </a>
            </div>
          )}
          {contact.linkedin.enabled && (
            <div className="footer-icon hidden-mobile">
              <a href={contact.linkedin.href}>
                <LinkedIn height={24} width={24} />
              </a>
            </div>
          )}
        </div>
        <div className="footer-row show-mobile">
          {contact.instagram.enabled && (
            <div className="footer-icon">
              <a href={contact.instagram.href}>
                <Instagram height={24} width={24} />
              </a>
            </div>
          )}
          {contact.facebook.enabled && (
            <div className="footer-icon">
              <a href={contact.facebook.href}>
                <Facebook height={24} width={24} />
              </a>
            </div>
          )}
          {contact.twitter.enabled && (
            <div className="footer-icon">
              <a href={contact.twitter.href}>
                <Twitter height={24} width={24} />
              </a>
            </div>
          )}
          {contact.linkedin.enabled && (
            <div className="footer-icon">
              <a href={contact.linkedin.href}>
                <LinkedIn height={24} width={24} />
              </a>
            </div>
          )}
        </div>
        <div className="footer-row">
          <div className="footer-copyright">
            <div>&copy; {copyrightYear}. All rights reserved.</div>
            <div>
              contact:{' '}
              <a href={`mailto:${contact.email.href}`}>{contact.email.href}</a>
            </div>
            <div style={{ marginTop: '5px', opacity: 0.6 }}>
              icons used under license from{' '}
              <a href={licenses.joyPixels}>JoyPixels</a> and{' '}
              <a href={licenses.fontAwesome}>FontAwesome</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
