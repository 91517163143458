import { useStaticQuery, graphql } from 'gatsby';
import { formatPostedAt } from '../utils';

interface Query {
  allAirtable: {
    edges: Array<{
      node: {
        data: {
          slug: string;
          heading: string;
          postedAt: string;
          updated: boolean;
          imageCredit: {
            childMarkdownRemark: {
              plainText: string;
            };
          };
          featureImage: {
            localFiles: Array<{
              childImageSharp: any;
            }>;
          };
        };
      };
    }>;
  };
}

export function useArticles() {
  const result: Query = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: { table: { eq: "articles" }, data: { active: { eq: true } } }
      ) {
        edges {
          node {
            data {
              slug
              heading
              postedAt
              updated
              imageCredit {
                childMarkdownRemark {
                  plainText
                }
              }
              featureImage {
                localFiles {
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return result.allAirtable.edges.map(e => {
    const { data } = e.node;
    return {
      ...data,
      postedAt: formatPostedAt(data.postedAt, data.updated),
    };
  });
}
