import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import { getEstimate, EstimateState } from '../store/estimate';
import './navbar.scss';

// import Logo from '../svgs/hamster.svg';
import { useArticles } from '../hooks/useArticles';

export default () => {
  const location = useLocation();

  const articles = useArticles();
  const showArticles = articles.length > 0;

  const [active, setActive] = useState(false);
  const toggle = () => setActive(a => !a);

  const estimate = useSelector(getEstimate);
  const getStartedText =
    estimate.state === EstimateState.Pending ? 'Get Started' : 'Go to Tool';
  const hideGetStarted = location.pathname.startsWith('/get-started');

  return (
    <div style={{ position: 'relative', maxWidth: '1200px', margin: '0 auto' }}>
      <nav id="navbar" className={`${active ? 'active' : ''}`}>
        <Link to="/" className="logo">
          {/* <Logo /> */}
          Lease or Leave
        </Link>
        <div className="navbar-full">
          {/* <Link to="/about" className="nav-link">
            About
          </Link> */}
          {/* <Link to="/faq" className="nav-link">
            FAQs
          </Link> */}
          {showArticles && (
            <Link to="/articles" className="nav-link">
              Articles
            </Link>
          )}
          {!hideGetStarted && (
            <Link
              to="/get-started"
              className="nav-button"
              style={{ marginLeft: 'auto' }}
            >
              {getStartedText}
            </Link>
          )}
        </div>
        <div className="navbar-burger" onClick={toggle}>
          <span />
          <span />
        </div>
      </nav>
      <ul className="navbar-mobile">
        {/* <li>
          <Link to="/about" className="nav-link">
            About
          </Link>
        </li> */}
        {/* <li>
          <Link to="/faq" className="nav-link">
            FAQs
          </Link>
        </li> */}
        {showArticles && (
          <li>
            <Link to="/articles" className="nav-link">
              Articles
            </Link>
          </li>
        )}
        <li>
          {!hideGetStarted && (
            <Link to="/get-started" className="nav-button">
              {getStartedText}
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};
