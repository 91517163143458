export const COLORS = {
  green: '#5ec294',
  dark: '#23394d',
  black: '#000',
  black30: 'rgba(0,0,0,0.3)',
  black40: 'rgba(0,0,0,0.4)',
  white: '#fff',
  white30: 'rgba(255,255,255,0.3)',
  white50: 'rgba(255,255,255,0.5)',
  white80: 'rgba(255,255,255,0.8)',
  gray: '#7d8c9b',
  lightGray: '#b2bfb9',
  lighterGray: '#e6eae8',
  border: '#e5e8eb',
  blue: 'rgb(73,129,242)',
  blue50: 'rgba(73,129,242,0.5)',
  navy: '#1f2b36',
  red: '#ea4e25',
};
