export function constrain(min: number, max: number, value: number) {
  return Math.max(min, Math.min(max, value));
}

export function scrubNumericInput(input: string): number | null {
  if (!input) return null;

  const scrubbed = input.replace(/[^0-9\.\-]/g, '');
  const parsed = parseFloat(scrubbed);
  return isNaN(parsed) ? null : parsed;
}

const months: { [month: string]: string } = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};
export function formatPostedAt(postedAt: string, updated: boolean) {
  const [year, month, day] = postedAt.split('-');
  return `${updated ? 'Updated' : 'Posted'} on ${months[month]} ${
    day.startsWith('0') ? day.substr(1) : day
  }, ${year}`;
}
